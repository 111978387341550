import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <div className="[ flexbox-404 content ]">
        <div className="[ heading ]">
          <div className="[ title-wrapper ]">
            <h1 className="[ title ]">
              404<span className="[ highlight break-line ]">::</span>not
              <span className="[ highlight ]">.</span>found
            </h1>
          </div>
        </div>
        <div className="[ grid-centered content body-404 ]">
          <div className="[ container-404 ]">
            <blockquote>
              <p>You’ve met with a terrible fate, haven’t you?</p>
            </blockquote>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
